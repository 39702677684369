import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import Users from "./components/Users";
import CreateUser from "./components/CreateUser";
import Projects from "./components/Projects";
import Payment from "./components/Payment";
import Voucher from "./components/Voucher";
import Profile from "./components/Profile";
import AllProjects from "./components/AllProjects";
import Documents from "./components/Documents";
import Pricing from "./components/Pricing";
import ContactDash from "./components/ContactDash";

function App() {
  const role = localStorage.getItem("role");
  return (
    <BrowserRouter basename="/">
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/packages" element={<Pricing />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard />}>
          {role === "admin" && <Route exact path="users" element={<Users />} />}
          {role === "admin" &&<Route exact path="create-user" element={<CreateUser />} />}

          <Route exact path="projects" element={<Projects />}>
            <Route exact path="documents" element={<Documents />} />
          </Route>

          {role === "admin" &&<Route exact path="allprojects" element={<AllProjects />} />}
          {role === "user" &&<Route exact path="payment" element={<Payment />} />}
          {role === "admin" &&<Route exact path="voucher" element={<Voucher />} />}
          <Route exact path="profile" element={<Profile />} />

          {role === "admin" &&<Route exact path="contact" element={<ContactDash />} />}
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
