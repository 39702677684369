import React, { useEffect, useState } from "react";
import { API_ENDPOINTS, HEADER } from "../context/config";
import Swal from "sweetalert2";
import axios from "axios";

const Voucher = () => {
  const [vouchers, setVouchers] = useState([]);
  const [editAmount, setEditAmount] = useState('');
  const [editStatus, setEditStatus] = useState('');

  useEffect(() => {
    fetchVouchers();
  }, []);

  const fetchVouchers = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.GET_VOUCHERS, HEADER);
      setVouchers(response.data);
    } catch (error) {
    }
  };

  const handleSubmit = async (event, voucherId) => {
    event.preventDefault();
  
    try {
      await axios.put(API_ENDPOINTS.UPDATE_VOUCHER + voucherId,
        { amount: editAmount, status: editStatus },
        HEADER
      );
      // Reset edit values and close modal
      setEditAmount('');
      setEditStatus('');
      Swal.fire('Success', 'Invoice updated', 'success');
      fetchVouchers();
    } catch (error) {
      Swal.fire('Error', 'Error updating Invoice', 'error');
    }
  };

  const onDeleteVoucher = async (voucherId) => {
    // Display a confirmation message
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this Invoice. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user confirmed the deletion
    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(API_ENDPOINTS.DELETE_VOUCHER + voucherId, HEADER);
        fetchVouchers(); // Refresh the voucher list
        Swal.fire("Deleted!", "The Invoice has been deleted.", "success");
      } catch (error) {
        Swal.fire("Error", "Server Error", "error");
      }
    }
  };

  return (
    <div className="container">
      <h5 className="card-title mb-3">Invoices</h5>
      {vouchers.length === 0 ? (
        <p>No Invoice found.</p>
      ) : (
        <div className="row">
          {vouchers.map((voucher) => (
            <div className="mb-4" key={voucher._id}>
              <div className="card">
                <h6 className="card-header">
                  User Name: {voucher.userId.name}
                </h6>
                <h6 className="card-header">
                  Project Name: {voucher.projectId.projectName}
                </h6>
                <div className="card-body">
                  <p>
                    <strong>User ID: </strong>
                    {voucher.userId._id}
                  </p>
                  <p>
                    <strong>Project ID: </strong>
                    {voucher.projectId._id}
                  </p>
                  <p className="text-capitalize fw-bold text-success">
                    <strong>Project Status: </strong>
                    {voucher.projectId.status}
                  </p>
                  <p>
                    <strong>Amount: </strong>
                    {voucher.amount}
                  </p>
                  <p className="text-capitalize fw-bold text-info">
                    <strong>Payment: </strong>
                    {voucher.status}
                  </p>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={`#exampleModal-${voucher._id}`}
                    >
                      <i className="fa fa-edit"> </i> Edit Invoice
                    </button>
                    <button type="button" className="btn btn-danger mx-2" onClick={() => onDeleteVoucher(voucher._id)}>
                      <i className="fa fa-trash"></i> Delete Invoice
                    </button>
                  </div>

                  <div
                    className="modal fade"
                    id={`exampleModal-${voucher._id}`}
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Edit Invoice
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          {/* <!-- Document Form --> */}

                          <form onSubmit={(event) => handleSubmit(event, voucher._id)}>
                            <div className="mb-3">
                              <label
                                htmlFor="voucherAmountInput"
                                className="form-label"
                              >
                                Enter Amount
                              </label>
                              <input
                                id="voucherAmountInput"
                                type="text"
                                className="form-control"
                                value={editAmount}
                                onChange={(e) => {
                                  const enteredValue = e.target.value;
                                  const onlyNumbers = enteredValue.replace(/\D/g, ""); // Remove non-numeric characters
                                  setEditAmount(onlyNumbers);
                                }}
                              />
                              <select
                                className="form-select my-3"
                                value={editStatus}
                                onChange={(e) => setEditStatus(e.target.value)}
                              >
                                <option value="">Select Status</option>
                                <option value="unpaid">Unpaid</option>
                                <option value="failed">Failed</option>
                                <option value="paid">Paid</option>
                              </select>
                            </div>
                            <button type="submit" className="btn btn-primary">
                              Update Invoice
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Voucher;
