import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS, HEADER } from '../context/config';
import Swal from 'sweetalert2';


const CreateUser = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const newUser = { name, email, password, role };
      const response = await axios.post(API_ENDPOINTS.CREATE_USER, newUser, HEADER);
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'User Created',
          text: 'User has been created successfully',
          html: `<strong>Name: </strong> ${name}<br><strong>Email: </strong> ${email}<br><strong>Role: </strong> ${role}`,
        });
        setName('');
        setEmail('');
        setPassword('');
        setRole('user');
      }
      } catch (error) {
      if (error.response.status === 400) {
      Swal.fire({
        icon: 'error',
        title: 'User Already Exists',
        text: 'A user with the provided email already exists',
      });
    } else if (error.response.status === 401) {
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: 'You are not authorized to perform this action',
      });
    }  else{    
      Swal.fire({
        icon: 'error',
        title: 'Internal Server Error',
        text: 'An internal server error occurred',
      });
    }
  }
  };

  return (
    <div className='container'>
      <h5 className='card-title mb-3'>Create New User</h5>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label className="form-label" htmlFor="name">Name:</label>
          <input className="form-control"
            type="text"
            id="name"
            value={name}
            minLength={3}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="email">Email:</label>
          <input className="form-control"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="password">Password:</label>
          <input className="form-control"
            type="password"
            id="password"
            value={password}
            minLength={8}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="role">Role:</label>
          <select className="form-select" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button className="btn btn-primary" type="submit">Create User</button>
      </form>
    </div>
  );
};

export default CreateUser;
