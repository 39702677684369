import React from "react";
import { Link } from "react-router-dom";

const Pricing = () => {
  return (
    <section className="py-5" style={{backgroundColor:"#1E4683"}}>
      <div className="container px-5 my-5">
        <div className="text-center mb-5">
          <h1 className="fw-bolder text-white">VM's Packages</h1>
        </div>
        <div className="row gx-5 justify-content-center">

          {/* <!-- Pricing card Starter--> */}

          <div className="col-lg-6 col-xl-4">
            <div className="card mb-5 mb-xl-0">
              <div className="card-body p-5">
                <h4 className="text-uppercase fw-bold">
                  Starter
                </h4>
                <p>
                  Monthly plan for a business that has under 200 monthly
                  transactions
                </p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Data Entry
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Recording daily financial transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Sales, Purchases and Expense Transactions
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    VAT Calculation
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Reconciliation of Bank Statements
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Supervision in document Filing
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Payroll Processing
                  </li>
                  <li className="text-muted">
                    <i className="bi bi-x"></i>
                    Inventory Management
                  </li>
                </ul>
                <hr />
                <p className="fw-bold">Preparation of Monthly Financials</p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Income Statement
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Balance Sheet
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Cash Flow
                  </li>
                </ul>
                <div className="d-grid">
                  <Link className="btn btn-outline-dark" to="/contact">
                    Request a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Pricing card Essential--> */}

          <div className="col-lg-6 col-xl-4">
            <div className="card mb-5 bg-dark text-white mb-xl-0">
              <div className="card-body p-5">
                <h4 className="text-uppercase fw-bold">
                  Essential
                </h4>
                <p>
                  Monthly plan for a business that has under 300 monthly
                  transactions
                </p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Data Entry
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Recording daily financial transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Sales, Purchases and Expense Transactions
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    VAT Calculation
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Reconciliation of Bank Statements
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Supervision in document Filing
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Payroll Processing
                  </li>
                  <li className="text-muted">
                    <i className="bi bi-x"></i>
                    Inventory Management
                  </li>
                </ul>
                <hr />
                <p className="fw-bold">Preparation of Monthly Financials</p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Income Statement
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Balance Sheet
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Cash Flow
                  </li>
                </ul>
                <div className="d-grid">
                  <Link className="btn btn-outline-light" to="/contact">
                  Request a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Pricing card Plus--> */}

          <div className="col-lg-6 col-xl-4">
            <div className="card">
              <div className="card-body p-5">
                <h4 className="text-uppercase fw-bold">
                  Plus
                </h4>
                <p >
                  Monthly plan for a business that has under 500 monthly
                  transactions
                </p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Data Entry
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Recording daily financial transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Sales, Purchases and Expense Transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    VAT Calculation
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Reconciliation of Bank Statements
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Supervision in document Filing
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Payroll Processing
                  </li>
                  <li className="text-muted">
                    <i className="bi bi-x"></i>
                    Inventory Management
                  </li>
                </ul>
                <hr />
                <p className="fw-bold">Preparation of Monthly Financials</p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Income Statement
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Balance Sheet
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x "></i>
                    Cash Flow
                  </li>
                </ul>
                <div className="d-grid">
                  <Link className="btn btn-outline-dark" to="/contact">
                    Request a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Pricing card Advance--> */}

          <div className="col-lg-6 col-xl-4 mt-5">
            <div className="card mb-5 mb-xl-0">
              <div className="card-body p-5">
                <h4 className="text-uppercase fw-bold">
                Advance
                </h4>
                <p>
                  Monthly plan for a business that has under 800 monthly
                  transactions
                </p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Data Entry
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Recording daily financial transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Sales, Purchases and Expense Transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    VAT Calculation
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Reconciliation of Bank Statements
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Supervision in document Filing
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x"></i>
                    Payroll Processing
                  </li>
                  <li className="text-muted">
                    <i className="bi bi-x"></i>
                    Inventory Management
                  </li>
                </ul>
                <hr />
                <p className="fw-bold">Preparation of Monthly Financials</p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Income Statement
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check text-primary"></i>
                    Balance Sheet
                  </li>
                  <li className="mb-2 text-muted">
                    <i className="bi bi-x text-primary"></i>
                    Cash Flow
                  </li>
                </ul>
                <div className="d-grid">
                  <Link className="btn btn-outline-dark" to="/contact">
                    Request a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>

                    {/* <!-- Pricing card Premium--> */}

          <div className="col-lg-6 col-xl-4 mt-5">
            <div className="card mb-5 mb-xl-0 bg-dark text-white">
              <div className="card-body p-5">
                <h4 className="text-uppercase fw-bold">
                Premium
                </h4>
                <p>
                  Monthly plan for a business that has under 1000 monthly
                  transactions
                </p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    Data Entry
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check "></i>
                    Recording daily financial transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    Sales, Purchases and Expense Transactions
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    VAT Calculation
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check "></i>
                    Reconciliation of Bank Statements
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    Supervision in document Filing
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check "></i>
                    Payroll Processing
                  </li>
                  <li className="">
                    <i className="bi bi-check "></i>
                    Inventory Management
                  </li>
                </ul>
                <hr />
                <p className="fw-bold">Preparation of Monthly Financials</p>
                <hr />
                <ul className="list-unstyled mb-4">
                  <li className="mb-2">
                    <i className="bi bi-check "></i>
                    Income Statement
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check "></i>
                    Balance Sheet
                  </li>
                  <li className="mb-2">
                    <i className="bi bi-check"></i>
                    Cash Flow
                  </li>
                </ul>
                <div className="d-grid">
                  <Link className="btn btn-outline-light" to="/contact">
                    Request a Quote
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
