import React from 'react'
import Faqs from './Faqs'
import about from "../Assets/About.png"
import excellence from "../Assets/excellence.jpeg"

const About = () => {
  return (
    <>
    {/* <!-- Header--> */}
    <header className="py-5 theme-color">
        <div className="container px-5">
            <div className="row justify-content-center">
                <div className="col-lg-8 col-xxl-6">
                    <div className="text-center my-5">
                        <h1 className="fw-bolder mb-3">Your Trusted Partner for Expert Virtual Accounting Services</h1>
                        <p className="mb-4 text-light lead">Virtual Muhasib is a team of expert accountants who has experience in the Middle East and Pakistan.
VM is a Pakistan-based accounting firm that is dedicated to helping small and medium-sized businesses manage their finances more efficiently and effectively. VM can do everything you would expect your physical accountant to do.</p>
                    </div>
                </div>
            </div>
        </div>
    </header>
    {/* <!-- About section one--> */}
    <section className="py-5 bg-light">
        <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
                <div className="col-lg-6"><img className="img-fluid rounded mb-5 mb-lg-0" src={about} alt="About us" /></div>
                <div className="col-lg-6">
                    <h2 className="fw-bolder">Simplify Your Finances with VM's Comprehensive Virtual Accounting Services</h2>
                    <p className="lead fw-normal text-muted mb-0">At VM, we understand that managing your finances can be a daunting task, especially if you don’t have the right expertise or resources. That’s why we offer a wide range of virtual accounting services that are designed to meet your unique needs and budget.
                    Our commitment to quality management and responsiveness enables us to provide outstanding services to our clients. Our reputation for quality comes from the quality of our services.
                    </p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- About section two--> */}
    <section className="py-5">
        <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
                <div className="col-lg-6 order-first order-lg-last"><img className="img-fluid rounded mb-5 mb-lg-0" src={excellence} alt="Delivering Excellence" /></div>
                <div className="col-lg-6">
                    <h2 className="fw-bolder">Delivering Excellence in Accounting Services</h2>
                    <p className="lead fw-normal text-muted mb-0">Muhasib hires certified accountants with CA, ACCA, CPA qualifications who work with our clients to understand their workings in order to provide them with exactly what they need.
A great bookkeeper can do wonders for your business – which is why it’s quite hard to find a good accountant. Muhasib Accounting, one of the leading outsourcing accounting firms, provides CPA and accounting services to businesses and individuals around the world.</p>
                </div>
            </div>
        </div>
    </section>

    {/* Joining VM */}
    <div className="theme-color pt-4 pb-5 my-4">
        <div className="container">
          <h2 className="h3 block-title text-center my-5">
            JOINING VM IS AS EASY AS 1-2-3
          </h2>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div className="col">
              <div className="card-body text-center">
                <span className="icon ">
                  <i className="fas fa-comments fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">Have a chat</h5>
                <p className="card-text text-light">
                  No need to commit - we'll give you a quick demo, show you some
                  sample reports and explain how we'll help you with your
                  finances
                </p>
              </div>
            </div>
            <div className="col">
              <div className="card-body text-center">
                <span className="icon">
                  <i className="fas fa-cogs fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">Get you set up</h5>
                <p className="card-text text-light">
                  Once you're ready to go ahead, we'll connect your books to
                  accounting software so we can start automating your accounting
                </p>
              </div>
            </div>
            <div className="col">
              <div className="card-body text-center">
                <span className="icon">
                  <i className="fas fa-check-circle fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">
                  Congratulation! You're all set.
                </h5>
                <p className="card-text text-light">
                  Now sit back, relax, and let us take you to new heights!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    <Faqs/>
    </>
      )
}

export default About