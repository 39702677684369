import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINTS } from "../context/config";
import axios from "axios";
import Swal from "sweetalert2";

const Login = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      // Make a POST request to the login API endpoint
      const response = await axios.post(API_ENDPOINTS.LOGIN, credentials);

      // Extract the token, name, and role from the response
      const { authtoken, name, role, userId } = response.data;

      // Save to local storage
      localStorage.setItem("token", authtoken);
      localStorage.setItem("name", name);
      localStorage.setItem("role", role);
      localStorage.setItem("Id", userId);


      navigate("/dashboard");
      window.location.reload();
    } catch (error) {
      if (error.response.status === 400) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Username or Password',
          text: 'Please Enter Correct Username and Password',
        });
      } else{    
        Swal.fire({
          icon: 'error',
          title: 'Internal Server Error',
          text: 'An internal server error occurred',
        });
      }
    }
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <section className="vh-100">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong shadow-lg">
              <div className="card-body p-5 text-center">
                <h3 className="mb-5">Sign in</h3>
                <div className="form-outline mb-4">
                  <input
                    placeholder="Email"
                    onChange={onChange}
                    value={credentials.email}
                    name="email"
                    type="email"
                    id="typeEmailX-2"
                    className="form-control form-control-lg"
                  />
                </div>
                <div className="form-outline mb-4">
                  <input
                    placeholder="Password"
                    onChange={onChange}
                    value={credentials.password}
                    name="password"
                    type="password"
                    id="typePasswordX-2"
                    className="form-control form-control-lg"
                  />
                </div>
                {/* <!-- Checkbox --> */}
                <div className="form-check d-flex justify-content-start mb-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="form1Example3"
                  />
                  <label className="form-check-label" htmlFor="form1Example3">
                    {" "}
                    Remember password{" "}
                  </label>
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Link className="text-danger" to="/">Forget password </Link>
                </div>
                <button
                  className="btn theme-color btn-lg btn-block"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Login
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
