import React, { useEffect, useState } from "react";
import { API_ENDPOINTS, HEADER } from "../context/config";
import Swal from "sweetalert2";
import axios from "axios";

const ContactDash = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.GET_CONTACTS, HEADER);
      setContacts(response.data);
    } catch (error) {
    }
  };

  const handleDeleteContact = async (id) => {
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this message. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user confirmed the deletion
    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(API_ENDPOINTS.DELETE_CONTACT + id, HEADER);
        fetchContacts(); // Refresh the contact list after deleting
      } catch (error) {
        Swal.fire("Error", "Server Error:", "error");
    }
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    const options = { hour: "numeric", minute: "numeric", second: "numeric" };
    return date.toLocaleTimeString(undefined, options);
  }

  return (
    <div className="container">
      <h5 className="card-title mb-3">Contact Forms</h5>
      {contacts.map((contact) => (
        <div className="card mb-3" key={contact._id}>
          <div className="card-body">
            <p className="">
              <strong>Name:</strong> {contact.name}
              <br />
              <strong>Email:</strong> {contact.email}
              <br />
              <strong>Phone:</strong> {contact.phone}
              <br />
              {contact.package && (
                <React.Fragment>
                  <strong>Package:</strong> {contact.package}
                  <br />
                </React.Fragment>
              )}
              <strong>Time:</strong> {formatDate(contact.date)} At{" "}
              {formatTime(contact.date)} <br />
              <strong>Message:</strong> {contact.message}
            </p>
            <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger"
              onClick={() => handleDeleteContact(contact._id)}
            >
              Delete
            </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactDash;
