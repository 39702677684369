import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { API_ENDPOINTS, HEADER } from "../context/config";
import axios from "axios";
import Swal from "sweetalert2";

const Documents = () => {
  const location = useLocation();
  const projectId = location?.state?.projectId;
  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const role = localStorage.getItem("role");

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line
  }, []);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_DOC + projectId,
        HEADER
      );
      // Sort the documents by uploadDate in descending order
      const sortedDocuments = response.data.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );
      setDocuments(sortedDocuments);
    } catch (error) {
      setDocuments([]);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    const options = { hour: "numeric", minute: "numeric", second: "numeric" };
    return date.toLocaleTimeString(undefined, options);
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    try{

    const formData = new FormData();
    formData.append("documentDescription", description);
    formData.append("file", file);

    axios
      .post(API_ENDPOINTS.ADD_DOC + projectId, formData, HEADER)
      .then((response) => {
        fetchDocuments();
        setFile(null);
        setDescription("");
        document.getElementById("fileInput").value = null;
      })
    }
    catch (error) {
        Swal.fire("Error", "Error Uploading File", "error");
      };
  };

  const onDeleteDocument = async (documentId) => {
    // Display a confirmation message
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this file. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user confirmed the deletion
    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(API_ENDPOINTS.DEL_DOC + documentId, HEADER);
        fetchDocuments(); // Refresh the project list
        Swal.fire("Deleted!", "File Deleted Successfully.", "success");
      } catch (error) {}
    }
  };

  return (
    <div className="container">
      <h5 className="card-title mb-3">Documents</h5>
      {/* Modal */}
      <div className="text-end mb-3">
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Add Document
        </button>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Document
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* <!-- Document Form --> */}

              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="descriptionInput" className="form-label">
                    Description *
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="descriptionInput"
                    rows="5"
                    value={description}
                    onChange={handleDescriptionChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="fileInput" className="form-label">
                    Select File *
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    id="fileInput"
                    onChange={handleFileChange}
                    accept=".xlsx, .docx, .pdf, .png, .jpg, .jpeg, .csv, .ppt"
                    data-max-size={20 * 1024 * 1024}
                    required
                  />
                  <small className="form-text text-muted">
                    Maximum file size: 20MB. <br /> Allowed file types: xlsx,
                    docx, pdf, png, jpg, jpeg, csv, ppt.
                  </small>
                </div>
                <button type="submit" className="btn btn-primary">
                  Add Document
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {documents.map((document) => (
        <div className="card mb-3" key={document._id}>
          <div className="card-body">
            <h6 className="mt-3">
              <strong>Description: </strong>
              {document.documentDescription}
            </h6>
            <p className="mt-3">
              <strong>Date: </strong>
              {formatDate(document.uploadDate)}
            </p>
            <p>
              <strong>Time: </strong>
              {formatTime(document.uploadDate)}
            </p>
            <p>
              <strong>File Name: </strong>
              {document.file.filename}
            </p>

            <a
              href={`${API_ENDPOINTS.DOWNLOAD_DOC}/${document._id}`}
              className="btn btn-primary"
              download={document.file.filename}
            >
              <i className="fas fa-download"></i> Download File
            </a>

            {role === "admin" && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger"
                  onClick={() => onDeleteDocument(document._id)}
                >
                  <i className="fas fa-trash"></i> Delete File
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
  
};

export default Documents;
