import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../Logo.png";

const Navbar = () => {
  let location = useLocation();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  return (
    <>
      <div className="row d-flex align-items-center theme-color py-1">
        <div className="container d-flex justify-content-center justify-content-md-between">
          <div className="contact-info d-flex align-items-center py-1">
            <a className="mx-4 small" href="tel:+966509933764">
              <i className="small fa fa-phone"> </i> +966509933764
            </a>
            <a className="small" href="mailto:info@virtualmuhasib.com">
              <i className="small fa fa-envelope"> </i> info@virtualmuhasib.com
            </a>
          </div>
        </div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light shadow-sm">
        <div className="container px-5">
          <Link className="navbar-brand fw-bold" to="/">
            <img src={logo} alt="Virtual Muhasib"/>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/" ? "active fw-bold" : ""
                  }`}
                  aria-current="page"
                  to="/"
                >
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/services" ? "active fw-bold" : ""
                  }`}
                  to="/services"
                >
                  SERVICES
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/packages" ? "active fw-bold" : ""
                  }`}
                  to="/packages"
                >
                  PACKAGES
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/contact" ? "active fw-bold" : ""
                  }`}
                  to="/contact"
                >
                  CONTACT
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    location.pathname === "/about" ? "active fw-bold" : ""
                  }`}
                  to="/about"
                >
                  ABOUT
                </Link>
              </li>
            </ul>
            {!isLoggedIn && (
            <div>
              <Link
                className="btn theme-color rounded-pill px-3 py-2 fa fa-sign-in"
                to="/login"
                role="button"
              >
                Login
              </Link>
            </div>
            )}
            {isLoggedIn && (
            <div>
              <Link
                className="btn theme-color rounded-pill px-3 py-2"
                to="/dashboard"
                role="button"
              >
                Dashboard
              </Link>
            </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
