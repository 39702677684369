import React from "react";
import { Link } from "react-router-dom";
import banner from "../Assets/Services_banner.png";
import startups from "../Assets/startups.jpeg";
import trader from "../Assets/SoleTrader.jpeg";
import business from "../Assets/SmallBusiness.jpeg";
import companies from "../Assets/Companies.jpeg";
import accounting from "../Assets/AccountingBookeeping.jpeg";
import noMessup from "../Assets/NoMessup.jpeg";
import Faqs from "./Faqs";
import Testimonial from "./Testimonial";

const Services = () => {
  
  return (
    <>
      <header className="py-5 gradinet">
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="display-5 fw-bolder text-white mb-2">
                  Accounting Services for every Business
                </h1>
                <p className="lead text-white mb-4">
                  Free yourself from financial admin. Our dedicated experts make
                  managing your money easier
                </p>
                <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
                  <Link
                    className="btn btn-dark btn-lg px-4 me-sm-3"
                    to="/packages"
                  >
                    PRICING
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
              <img
                className="my-5"
                src={banner}
                alt="Services Banner"
                style={{
                  maxWidth: "550px",
                  height: "300px",
                  marginTop: "2rem",
                }}
              />
            </div>
          </div>
        </div>
      </header>

      {/* services */}
      <div className="theme-color py-5">
        <div className="container pt-3 mb-3">
          <h2 className="h3 block-title text-center mt-4">
            COUNT ON US FOR YOUR COUNTING NEEDS!
          </h2>
          <div className="row pt-5 mt-30 d-flex align-items-stretch">
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-calculator fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">BOOKEEPING</h5>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.We collect all your documents and
                    accurately match them against your transactions every day
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-chart-bar fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">MANAGEMENT REPORTS</h5>
                  <p className="card-text">
                    We prepare Profit & Loss,Balance sheets, Aged Payables and
                    Recievables
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-file-invoice-dollar fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">VAT</h5>
                  <p className="card-text">
                    Help you with setting it up and make sure you're compliant
                    with government
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-money-bill-wave fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">CASH FLOW</h5>
                  <p className="card-text">
                    Helps you manage your credit and reduce the cost of credit.
                    We can advise you on structuring your credit lines.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-university fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">BANK RECONILIATION</h5>
                  <p className="card-text">
                    Get your bank account transactions categorised and
                    reconciled each day
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 mb-30 pb-5">
              <div className="card text-center mb-3 h-100">
                <div className="card-body">
                  <div className="rounded-circle mx-auto text-center">
                    <i className="fas fa-money-check-alt fa-3x head-icon mb-3"></i>
                  </div>
                  <h5 className="card-title text-dark">PAYROLL</h5>
                  <p className="card-text">
                    Leave payroll to us: We'll handle the details so you can
                    focus on growing your business
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Who we work with */}
      <div className="container py-5">
        <h2 className="h3 block-title text-center my-5">WHO WE WORK WITH?</h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
          <div className="col">
            <div className="card text-center">
              <img src={startups} className="card-img-top" alt="Startups" />
              <div className="card-body">
                <h5 className="card-title">Startups</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-center">
              <img src={trader} className="card-img-top" alt="Sole Trader" />
              <div className="card-body">
                <h5 className="card-title">Sole Trader</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-center">
              <img
                src={business}
                className="card-img-top"
                alt="Small Business"
              />
              <div className="card-body">
                <h5 className="card-title">Small Business</h5>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card text-center">
              <img
                src={companies}
                className="card-img-top"
                alt="Growing Companies"
              />
              <div className="card-body">
                <h5 className="card-title">Growing Companies</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- About section two--> */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={noMessup}
                alt="No Messups"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder mb-3" style={{ color: "#1E4683" }}>
                NO MESS-UPS
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                Our top priority is to ensure that you stay ahead of your
                accounting and compliance obligations. This means that we
                guarantee error-free reports, submitted with accuracy and
                timeliness to the relevant government bodies.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
      {/* Joining VM */}
      <div className="theme-color pt-4 pb-5 mt-5">
        <div className="container">
          <h2 className="h3 block-title text-center my-5">
            JOINING VM IS AS EASY AS 1-2-3
          </h2>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div className="col">
              <div className="card-body text-center">
                <span className="icon ">
                  <i className="fas fa-comments fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">Have a chat</h5>
                <p className="card-text text-light">
                  No need to commit - we'll give you a quick demo, show you some
                  sample reports and explain how we'll help you with your
                  finances
                </p>
              </div>
            </div>
            <div className="col">
              <div className="card-body text-center">
                <span className="icon">
                  <i className="fas fa-cogs fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">Get you set up</h5>
                <p className="card-text text-light">
                  Once you're ready to go ahead, we'll connect your books to
                  accounting software so we can start automating your accounting
                </p>
              </div>
            </div>
            <div className="col">
              <div className="card-body text-center">
                <span className="icon">
                  <i className="fas fa-check-circle fa-4x"></i>
                </span>
                <h5 className="card-title mt-3">
                  Congratulation! You're all set.
                </h5>
                <p className="card-text text-light">
                  Now sit back, relax, and let us take you to new heights!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- About section one--> */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={accounting}
                alt="Accounting and Bookeeping"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder mb-4">
                Accounting and bookkeeping services for all types of businesses
              </h2>
              <p className="lead fw-normal text-muted mb-0">
                Whether you are a sole trader, a small business, or a growing
                company, you can rely on us for full-service online accounting
                support.
                <br />
                <br />
                We work with clients in different industry sectors, and they
                trust us because we know the unique problems they face in their
                business.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Faqs />
    </>
  );
};

export default Services;
