import React from "react";
import logo from "../Logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* // <!-- Footer --> */}
      <div className="text-center text-lg-start bg-white text-muted">
        {/* <!-- Section: Links  --> */}
        <section className="">
          <div className="container text-center text-md-start mt-5">
            {/* <!-- Grid row --> */}
            <div className="row mt-3">
              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                {/* <!-- Content --> */}
                <img src={logo} alt="Virtual Muhasib" />

                <p className="mt-4">
                  With Muhasib Accounting, you get a streamlined, trusted
                  accounting outsourcing solution in one place, and you could
                  put an end to the stress of accounting, bookkeeping, payroll,
                  and tax prep.
                </p>
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Menu</h6>
                <p>
                  <Link className="text-reset" to="/">
                  Home
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/about">
                    About
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/services">
                  Services
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/pricing">
                    Pricing
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/contact">
                    Contact
                  </Link>
                </p>
                
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <Link className="text-reset" to="/">
                    Privacy Policy
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/">
                  Terms and Conditions
                  </Link>
                </p>
                <p>
                  <Link className="text-reset" to="/">
                  Refund and Return Policy
                  </Link>
                </p>
                
              </div>
              {/* <!-- Grid column --> */}

              {/* <!-- Grid column --> */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                {/* <!-- Links --> */}
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <a
                    className="text-secondary"
                    href="mailto:info@virtualmuhasib.com"
                  >
                    <i className="fas fa-envelope me-3"></i>
                    info@virtualmuhasib.com
                  </a>
                </p>
                <p>
                  <i className="fas fa-phone me-3 "></i> Saudi Arabia:{" "}
                  <a className="text-secondary" href="tel:+966509933764">
                    +966 509 933764
                  </a> <br /> &emsp; &emsp; &emsp; &emsp; &emsp; &emsp;
                  <a className="text-secondary" href="tel:+966561927559">
                  +966 561 927559</a>
                </p>
                <p>
                  <i className="fas fa-phone me-3"></i> Pakistan:{" "}
                  <a className="text-secondary" href="tel:+923208754190">
                  +92 320 8754190 </a> <br /> &emsp; &emsp; &emsp; &emsp; &emsp; 
                  <a className="text-secondary" href="tel:+923333378095">
                  +92 333 3378095</a>
                </p>
              </div>
              {/* <!-- Grid column --> */}
            </div>
            {/* <!-- Grid row --> */}
          </div>
        </section>
        {/* <!-- Section: Links  --> */}

        {/* <!-- Copyright --> */}
        <hr />
        <div className="text-center">
          Copyright © 2023 |
          <a
            className="text-reset fw-bold py-4"
            href="https://virtualmuhasib.com/"
          >
            {" "}
            Virtual Muhasib
          </a>
          , All Rights Reserved.
        </div>
        {/* <!-- Copyright --> */}
      </div>
      {/* <!-- Footer --> */}
    </>
  );
};

export default Footer;
