import React, { useEffect, useState } from "react";
import { API_ENDPOINTS, HEADER } from "../context/config";
import axios from "axios";
import Swal from "sweetalert2";

const AllProjects = () => {
  const [projects, setProjects] = useState([]);
  const [amount, setAmount] = useState("");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.GET_PROJECTS, HEADER);
        setProjects(response.data);
      } catch (error) {
        console.log("Error fetching project data:", error);
      }
    };

    fetchProjects();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const countStatus = (status) => {
    return projects.filter((project) => project.status === status).length;
  };

  const pendingCount = countStatus("pending");
  const inProgressCount = countStatus("in_progress");
  const completedCount = countStatus("completed");
  const paymentCount = countStatus("payment pending");
  const failedCount = countStatus("failed");

  const handleSubmit = async (event, projectId, userId) => {
    event.preventDefault();

    try {
      await axios.post(
        API_ENDPOINTS.ADD_VOUCHER + projectId,
        { userId, amount },
        HEADER
      );
      setAmount("");
      Swal.fire("Success", "Invoice Added", "success");
    } catch (error) {
      Swal.fire("Error", "Error Adding Invoice", "error");
    }
  };

  return (
    <div className="container">
      <div className="row my-2">
        <div className="col my-1">
          <div className="box rounded text-center bg-primary text-white py-1">
            <h6>PROJECTS</h6>
            <p>{projects.length}</p>
          </div>
        </div>
        <div className="col my-1">
          <div className="box rounded text-center bg-success text-white py-1">
            <h6>COMPLETED</h6>
            <p>{completedCount}</p>
          </div>
        </div>
        <div className="col my-1">
          <div className="box rounded text-center bg-secondary text-white py-1">
            <h6>PENDING</h6>
            <p>{pendingCount}</p>
          </div>
        </div>
        <div className="col my-1">
          <div className="box rounded text-center bg-info text-white py-1">
            <h6>PROGRESS</h6>
            <p>{inProgressCount}</p>
          </div>
        </div>
        <div className="col my-1">
          <div className="box rounded text-center bg-danger text-white py-1">
            <h6>FAILED</h6>
            <p>{failedCount}</p>
          </div>
        </div>
        <div className="col my-1">
          <div className="box rounded text-center bg-dark text-white py-1">
            <h6>PAYMENTS</h6>
            <p>{paymentCount}</p>
          </div>
        </div>
        <div className="my-3">
          <h5 className="card-title">Projects</h5>
        </div>
      </div>

      {projects.length !== 0 ? (
        projects.map((project) => (
          <div key={project._id} className="card my-3">
            <h6 className="card-header">User Name: {project.userId.name}</h6>
            <h6 className="card-header">Project Name: {project.projectName}</h6>
            <div className="card-body">
              <p>
                <strong>User ID: </strong>
                {project.userId._id}
              </p>
              <p>
                <strong>Project ID: </strong>
                {project._id}
              </p>
              <p className="text-capitalize fw-bold text-success">
                <strong>Status: </strong>
                {project.status}
              </p>
              <p>
                <strong>Start Date: </strong>
                {formatDate(project.startDate)}
              </p>
              <p>
                <strong>Description: </strong>
                {project.projectDescription}
              </p>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target={`#exampleModal-${project._id}`}
                >
                  <i className="fa fa-credit-card"></i> Generate Invoice
                </button>
              </div>
              <div
                className="modal fade"
                id={`exampleModal-${project._id}`}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Generate Invoice
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      {/* <!-- Voucher Form --> */}

                      <form
                        onSubmit={(event) =>
                          handleSubmit(event, project._id, project.userId._id)
                        }
                      >
                        <div className="mb-3">
                          <label htmlFor="userIdInput" className="form-label">
                            User ID
                          </label>
                          <input
                            id="userIdInput"
                            type="text"
                            className="form-control"
                            value={project.userId._id}
                            disabled
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="projectIdInput"
                            className="form-label"
                          >
                            Project ID
                          </label>
                          <input
                            id="projectIdInput"
                            type="text"
                            className="form-control"
                            value={project._id}
                            disabled
                          />
                        </div>

                        <div className="mb-3">
                          <label
                            htmlFor="voucherAmountInput"
                            className="form-label"
                          >
                            Enter Amount
                          </label>
                          <input
                            id="voucherAmountInput"
                            type="text"
                            className="form-control"
                            value={amount}
                            onChange={(e) => {
                              const enteredValue = e.target.value;
                              const onlyNumbers = enteredValue.replace(
                                /\D/g,
                                ""
                              ); // Remove non-numeric characters
                              setAmount(onlyNumbers);
                            }}
                            required
                          />
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Add Invoice
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>
          <p>No project found.</p>
        </div>
      )}
    </div>
  );
};

export default AllProjects;
