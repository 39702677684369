import React from "react";
import { useNavigate } from "react-router-dom";

const UserList = ({ users ,onDeleteUser }) => {
  let navigate = useNavigate();

  const handleViewProjects = (userId,userName) => {
    navigate("projects",{ state: { userId,userName } });
  };

  return (
    <div>
      <h5 className="card-title mb-3">User Information</h5>
      {users.map((user) => (
        <div key={user.userId._id} className="card mb-1">
          <div className="card-body">
            <p ><strong>User Id: </strong>{user.userId._id}</p>
            <p ><strong>Name: </strong>{user.userId.name}</p>
            <p ><strong>Email: </strong>{user.email}</p>
            <p className={`text-capitalize ${user.userId.role === 'admin' ? 'fw-bold text-uppercase' : ''}`}><strong>Role: </strong>{user.userId.role}</p>
            {user.userId.role !== "admin" && 
            <div className="position-relative">
          <button className="btn btn-primary start-0" onClick={() => handleViewProjects(user.userId._id,user.userId.name)}>
            <i className="fas fa-folder"></i> Projects
          </button>
            <button className="btn btn-danger position-absolute end-0" onClick={() => onDeleteUser(user.userId._id)}>
            <i className="fas fa-trash"></i> Delete User
          </button>
          </div>
          }
        </div>
          </div>
      ))}
    </div>
  );
};

export default UserList;
