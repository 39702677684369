import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINTS, HEADER } from "../context/config";
import axios from "axios";
import Swal from "sweetalert2";

const Projects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const userId = location?.state?.userId || localStorage.getItem("Id");
  const userName = location?.state?.userName;
  const userRole = localStorage.getItem("role");
  const [status, setStatus] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  useEffect(() => {
    fetchProjects();
    // eslint-disable-next-line
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_PROJECT + userId,
        HEADER
      );
      setProjects(response.data);
    } catch (error) {
      setProjects([]);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const onDeleteProject = async (projectId) => {
    // Display a confirmation message
    const confirmResult = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this Project. This action will delete all its Documents and Invoices (if any).",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user confirmed the deletion
    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(API_ENDPOINTS.DELETE_PROJECT + projectId, HEADER);
        fetchProjects(); // Refresh the project list
        Swal.fire("Deleted!", "The Project has been deleted.", "success");
      } catch (error) {
      }
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUpdateStatus = (projectId) => {
    const data = {
      status: status,
    };
    axios
      .put(API_ENDPOINTS.UPDATE_PROJECT + projectId, data, HEADER)
      .then((response) => {
        fetchProjects();
        Swal.fire("Success", "Status Updated", "success");
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "An error occurred while updating project status",
          "error"
        );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create the project object with the form data
    const project = {
      projectName,
      projectDescription,
    };

    // Send the project data to the "addnewproject" route
    axios
      .post(API_ENDPOINTS.CREATE_PROJECT + userId, project, HEADER)
      .then((response) => {
        // Handle the response from the server
        Swal.fire(
          "New Project Added",
          "New Project has been created for this user",
          "success"
        );
        // Reset the form fields
        setProjectName("");
        setProjectDescription("");
        fetchProjects();
      })
      .catch((error) => {
        Swal.fire(
          "Error",
          "An error occurred while adding new project",
          "error"
        );
      });
  };

  const handleViewDocuments = (projectId) => {
    navigate("projects/documents", { state: { projectId } });
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-between">
          <div className="col mb-3 mt-2">
            <h5 className="card-title">Projects</h5>
          </div>
          {userName && (
            <button
              className="btn btn-primary mb-3 mt-2 col-3 col-lg-2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              New Project
            </button>
          )}
          {/* Show Modal to add new Project */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    New Project
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {/* <!-- Project Form --> */}

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description:
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        rows="5"
                        value={projectDescription}
                        onChange={(e) => setProjectDescription(e.target.value)}
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Add Project
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {projects.length === 0 ? (
          <p>No Project.</p>
        ) : (
          <div>
            {projects.map((project) => (
              <div className="mb-3" key={project._id}>
                <div className="card">
                  {userName && (
                    <h6 className="card-header">User Name: {userName}</h6>
                  )}
                  <h6 className="card-header">
                    Project Name: {project.projectName}
                  </h6>
                  <div className="card-body">
                    <p>
                      <strong>Project Description: </strong>
                      {project.projectDescription}
                    </p>
                    <p className="text-capitalize">
                      <strong>Project Status: </strong>
                      {project.status}
                    </p>
                    <p>
                      <strong>Start Date: </strong>
                      {formatDate(project.startDate)}
                    </p>
                    <div>
                      <button
                        className="btn btn-primary"
                        onClick={() => handleViewDocuments(project._id)}
                      >
                        <i className="fas fa-file"></i> Documents
                      </button>
                      {userRole === "admin" && (
                        <div className="d-flex justify-content-end">
                          <select
                            className="form-select w-25 me-2"
                            // value={status}
                            onChange={handleStatusChange}
                          >
                            <option value="pending">Pending</option>
                            <option value="in_progress">In Progress</option>
                            <option value="payment pending">
                              Payment Pending
                            </option>
                            <option value="failed">Failed</option>
                            <option value="completed">Completed</option>
                          </select>
                          <button
                            className="btn btn-success me-1"
                            onClick={() => handleUpdateStatus(project._id)}
                          >
                            <i className="fa fa-refresh"></i> Update Status
                          </button>

                          <button
                            className="btn btn-danger"
                            onClick={() => onDeleteProject(project._id)}
                          >
                            <i className="fas fa-trash"></i> Delete Project
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Projects;
