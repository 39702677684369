import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import jwt_decode from 'jwt-decode';
import Users from "./Users";
import CreateUser from "./CreateUser";
import Projects from "./Projects";
import Payment from "./Payment";
import Voucher from "./Voucher";
import Swal from "sweetalert2";
import Profile from "./Profile";
import AllProjects from "./AllProjects";
import Documents from "./Documents";
import ContactDash from "./ContactDash";

const Dashboard = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const userName = localStorage.getItem("name");
  const role = localStorage.getItem('role');

  // Login Required
  useEffect(() => {
    const checkToken = () => {
      const authtoken = localStorage.getItem("token");
      if (authtoken) {
        const decodedToken = jwt_decode(authtoken);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp < currentTime) {
          localStorage.removeItem("token");
          localStorage.removeItem("name");
          localStorage.removeItem("role");
          localStorage.removeItem("Id");
          navigate("/login");
          window.location.reload();
        }
      }
    };

    checkToken();
  }, [navigate]);

  const handleLogout = () => {
    Swal.fire({
      title: "Logout",
      text: "Are you sure you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        // Clear the user data from localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("role");
        localStorage.removeItem("Id");

        navigate("/login");
        window.location.reload();
      }
    });
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark theme-color">
        <div className="container">
          <span className="navbar-text">WELCOME {userName}</span>
          <h2 className="mx-auto lead fw-bolder">Virtual Muhasib Dashboard</h2>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          >
            <div className="position-sticky pt-3">
              <ul className="nav flex-column">
                {role === 'admin' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/users"
                        ? "active fw-bold"
                        : ""
                    }`}
                    aria-current="page"
                    to="users"
                  >
                    <i className="fa fa-users px-1"></i>
                    Users
                  </Link>
                </li>
                )}
                {role === 'admin' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/create-user"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="create-user"
                  >
                    <i className="fa fa-user px-1"></i>
                    Create User
                  </Link>
                </li>
                )}
                {role === 'user' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/projects"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="projects"
                  >
                    <i className="fa fa-folder px-1"></i>
                    Projects
                  </Link>
                </li>
                )}
                {role === 'admin' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/allprojects"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="allprojects"
                  >
                    <i className="fa fa-folder px-1"></i>
                    Projects
                  </Link>
                </li>
                )}
                {role === 'user' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/payment"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="payment"
                  >
                    <i className="fa fa-credit-card px-1"></i>
                    Invoice
                  </Link>
                </li>
                )}
                {role === 'admin' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/voucher"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="voucher"
                  >
                    <i className="fa fa-credit-card px-1"></i>
                    Invoices
                  </Link>
                </li>
                )}
                {role === 'admin' && (
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/contact"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="contact"
                  >
                    <i className="fa fa-address-book px-1"></i>
                    Contact Forms
                  </Link>
                </li>
                )}
                <li className="nav-item">
                  <Link
                    className={`nav-link ${
                      location.pathname === "/dashboard/profile"
                        ? "active fw-bold"
                        : ""
                    }`}
                    to="profile"
                  >
                    <i className="fa fa-key px-1"></i>
                    Profile
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    type="button"
                    onClick={handleLogout}
                  >
                    <i className="fa fa-sign-out px-1"></i>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
              {location.pathname === "/dashboard" && (
                <div className="container">
                  <div className="row">
                    <div className="col-md-10 offset-md-1">
                      <h2 className="text-center mb-4">
                        Welcome to the Dashboard of Virtual Muhasib
                      </h2>
                      <p>
                        Welcome to Virtual Muhasib, your one-stop solution for
                        all your accounting needs. This dashboard is designed to
                        provide you with easy access to all your services and
                        project updates. Let's explore what you can do here:
                      </p>
                      <ul>
                        <li>
                          <strong>Dashboard Menu:</strong> Find the menu on the left-hand side to
                          navigate through different sections.
                        </li>
                        <li>
                        <strong>Projects:</strong> In the Projects tab, you can view all your
                          ongoing projects and track their progress.
                        </li>
                        <li>
                        <strong>Documents:</strong> Upload any necessary documents related to
                          your projects to facilitate communication with your
                          muhasib.
                        </li>
                        <li>
                        <strong>Payments:</strong> Make payments for your projects to ensure
                          timely completion and smooth transactions.
                        </li>
                        <li>
                        <strong>Profile:</strong> You can update your profile information and
                          manage your account settings.
                        </li>
                      </ul>
                      <p>
                        We hope you find this dashboard user-friendly and
                        convenient to manage your accounting tasks. If you have
                        any questions or need assistance, please feel free to
                        reach out to our support team. Happy Accounting!
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {location.pathname === "/dashboard/users" && <Users />}
              {location.pathname === "/dashboard/create-user" && <CreateUser />}
              {location.pathname === "/dashboard/projects" && <Projects />}
              {location.pathname === "/dashboard/projects/documents" && <Documents />}
              {location.pathname === "/dashboard/allprojects" && <AllProjects />}
              {location.pathname === "/dashboard/payment" && <Payment />}
              {location.pathname === "/dashboard/voucher" && <Voucher />}
              {location.pathname === "/dashboard/profile" && <Profile />}
              {location.pathname === "/dashboard/contact" && <ContactDash />}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
