import React from "react";
import Jessica from "../Assets/Jessica.jpeg";
import Sharani from "../Assets/Al Sharani.jpeg";
import Myriam from "../Assets/Myriam yaser.jpeg";
import Michael from "../Assets/Michael T.jpeg";
import Abdullah from "../Assets/Abdullah Khalid.jpeg";

const Testimonial = () => {
  return (
    <div className="container">
      <h2 className="text-center fw-bolder my-5">WHAT OUR CLIENT SAY ABOUT</h2>
      <div
        id="testimonialCarousel"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="img-box">
              <img src={Jessica} alt="Jessica" />
            </div>
            <p className="testimonial">
              "When I started my online venture, I had no idea how to manage the
              financial side of my business. That's when I found VM's virtual
              accounting services. Not only have they saved me countless hours
              of number-crunching, but their expert financial advice has also
              helped me make informed decisions and grow my business
              exponentially. VM, you're a lifesaver!"
            </p>
            <p className="overview">
              <b>Jessica</b>Online Business Owner
            </p>
            <div className="star-rating">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-box">
              <img src={Sharani} alt="AL Sharani" />
            </div>
            <p className="testimonial">
              "لقد استخدمت خدمات المحاسبة الافتراضية الخاصة بـ VM لأكثر من عام
              الآن وأنا سعيد للغاية بالنتائج. فريقهم متمكن للغاية، احترافي
              ودائمًا متاح للإجابة على أي أسئلة لدي. لقد ساعدوني على تبسيط
              عمليات المحاسبة الخاصة بي وتوفير الوقت والمال. أوصي بخدماتهم بشدة
              لأي شخص يحتاج إلى دعم المحاسبة الافتراضية."
            </p>
            <p className="overview">
              <b>Al Sharani</b>Business Owner
            </p>
            <div className="star-rating">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-box">
              <img src={Myriam} alt="Myriam Yaser" />
            </div>
            <p className="testimonial">
              "As a small business owner, I need to stay on top of my finances, but I don't always have the time or expertise to do so. That's where VM's virtual accounting services come in. They are experts in their field and have helped me stay organized, understand my financials, and make informed decisions for my business. I can't recommend their services enough."
            </p>
            <p className="overview">
              <b>Myriam Yaser</b>Entrepreneur
            </p>
            <div className="star-rating">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star-o"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-box">
              <img src={Michael} alt="Michael T" />
            </div>
            <p className="testimonial">
              "As a busy professional, I needed an accounting partner who could help me manage my finances without burdening me with complex financial jargon. VM's virtual accounting services were the perfect fit. Their vast experience showcased the value of their services. Their team consistently delivers accurate and on-time reports, and their dedicated support has made my life so much easier. I can't imagine managing my finances without VM!"
            </p>
            <p className="overview">
              <b>Michael T</b>Consultant
            </p>
            <div className="star-rating">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
              </ul>
            </div>
          </div>
          <div className="carousel-item">
            <div className="img-box">
              <img src={Abdullah} alt="Abdullah Khalid" />
            </div>
            <p className="testimonial">
              "I was hesitant to switch to virtual accounting services, but VM made the transition seamless and easy. Their team is incredibly knowledgeable and efficient. I appreciate how responsive and reliable they are, and I feel confident that my finances are in good hands. I would definitely recommend their services to anyone looking for virtual accounting support."
            </p>
            <p className="overview">
              <b>Abdullah Khalid</b>Freelancer
            </p>
            <div className="star-rating">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star"></i>
                </li>
                <li className="list-inline-item">
                  <i className="fa fa-star-o"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#testimonialCarousel"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
