import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_ENDPOINTS, HEADER } from "../context/config";

const Payment = () => {
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    const userId = localStorage.getItem("Id");
    try {
      const response = await axios.get(API_ENDPOINTS.GET_VOUCHER + userId, HEADER); 
      setPayments(response.data);
    } catch (error) {

    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container">
      <div className="col mb-3 mt-2">
            <h5 className="card-title mb-3">Invoice</h5>
            </div>
      {payments && payments.length > 0 ? (
        <div className="row">
          {payments.map((payment) => (
            <div className="col-md-6" key={payment._id}>
              <div className="card mb-4">
                <div className="card-body">
                  <h5 className="card-header">{payment.projectId.projectName}</h5>
                  <p className="card-text">{payment.projectId.projectDescription}</p>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item">
                      <strong>Amount:</strong> ${payment.amount}
                    </li>
                    <li className={`list-group-item ${payment.status === "paid" ? "text-success" : "text-danger"}`}>
                      <strong>Status:</strong> {payment.status}
                    </li>
                    <li className="list-group-item">
                      <strong>Voucher Date: </strong>
                      {formatDate(payment.voucherDate)}
                    </li>
                  </ul>
                <div className="text-end">
                  <button type="button" className="btn btn-primary">Pay Now</button>
                </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No Invoice.</p>
      )}
    </div>
  );
};

export default Payment;
