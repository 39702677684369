import React from "react";
import { API_ENDPOINTS } from "../context/config";
import axios from "axios";
import Swal from "sweetalert2";

const Contact = () => {

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get form values
  const name = document.getElementById('name').value;
  const email = document.getElementById('email').value;
  const phone = document.getElementById('phone').value;
  const vmPackage = document.getElementById('package').value;
  const message = document.getElementById('message').value;

  // Create contact object
  const contact = {
    name,
    email,
    phone,
    "package": vmPackage,
    message
  };

  try {
    // Send POST request to the backend API
    await axios.post(API_ENDPOINTS.ADD_CONTACT, contact);

    // Clear the form after successful submission
    document.getElementById('contactForm').reset();

    Swal.fire("Message Sent", "Thanks for contacting us, we'll get back to you shortly", "success");

} catch (error) {

}
  }  

  return (
    <section className="py-5">
      <div className="container px-5">
        {/* <!-- Contact form--> */}
        <div className="py-4 px-4 px-md-5 mb-5">
          <div className="text-center mb-5">
            <div className="mb-3">
              <i className="px-3 py-2 theme-color rounded-3 bi bi-envelope"></i>
            </div>
            <h1 className="fw-bolder">Get in touch</h1>
            <p className="lead fw-normal text-muted mb-0">
              We'd love to hear from you
            </p>
          </div>
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <form id="contactForm" onSubmit={handleSubmit}>
                {/* <!-- Name input--> */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    placeholder="Full Name"
                    required
                  />
                  <label htmlFor="name">Full name *</label>
                </div>
                {/* <!-- Email address input--> */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="Email"
                    required
                  />
                  <label htmlFor="email">Email address *</label>
                </div>
                {/* <!-- Phone number input--> */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="phone"
                    type="tel"
                    placeholder="Phone"
                    required
                  />
                  <label htmlFor="phone">Phone number *</label>
                </div>
                <div className="form-floating mb-3">
                  <select className="form-select" id="package">
                    <option value="" selected>
                      ...
                    </option>
                    <option value="Starter">STARTER</option>
                    <option value="Essential">ESSENTIAL</option>
                    <option value="Plus">PLUS</option>
                    <option value="Advance">ADVANCE</option>
                    <option value="Premium">PREMIUM</option>
                  </select>
                  <label htmlFor="package">Select Package</label>
                </div>
                {/* <!-- Message input--> */}
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    id="message"
                    type="text"
                    style={{ height: "10rem" }}
                    placeholder="Message"
                    required
                  ></textarea>
                  <label htmlFor="message">Message *</label>
                </div>
                
                <div className="d-grid">
                  <button
                    className="btn theme-color btn-lg"
                    id="submitButton"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- Contact cards--> */}
        <div className="row gx-5 py-5">
          <div className="col-sm mb-3">
            <div className="mb-3">
              <i className="px-3 py-2 theme-color rounded-3 bi bi-chat-dots"></i>
            </div>
            <div className="h5 mb-2">Chat with us</div>
            <p className="text-muted ">
              Click here to chat live with our support specialists on Whatsapp.
            </p>
          </div>
          <div className="col-sm mb-3">
            <div className="mb-3">
              <i className="px-3 py-2 theme-color rounded-3 bi bi-people"></i>
            </div>
            <div className="h5">Contact us</div>
            <p className="text-muted">
              Feel free to contact us through the form provided above.
            </p>
          </div>
          <div className="col-sm mb-3">
            <div className="mb-3">
              <i className="px-3 py-2 theme-color rounded-3 bi bi-question-circle"></i>
            </div>
            <div className="h5">Support center</div>
            <p className="text-muted ">
              Email us at <a className="text-primary" href="mailto:info@virtualmuhasib.com">contact@virtualmuhasib.com</a>
            </p>
          </div>
          <div className="col-sm mb-3">
            <div className="mb-3">
              <i className=" px-3 py-2 theme-color rounded-3 bi bi-telephone"></i>
            </div>
            <div className="h5">Call us</div>
            <p className="text-muted">
              Call us during normal business hours at (966) 509-933764.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
