import { useState } from 'react';
import { API_ENDPOINTS, HEADER } from '../context/config';
import UserList from './UserList';
import { useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const Users = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(API_ENDPOINTS.USERS,HEADER);
      setUsers(response.data);
    } catch (error) {
      setUsers([]);
    }
  };

  const handleDeleteUser = async (userId) => {

    // Display a confirmation message
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: 'You are about to delete this user. This action delete all its Data includes Projects, Documents and Invoices (If any).',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    });
  
    // Check if the user confirmed the deletion
    if (confirmResult.isConfirmed) {
      try {
        await axios.delete(API_ENDPOINTS.DELETE_USER + userId, HEADER);
        fetchUsers(); // Refresh the user list
        Swal.fire('Deleted!', 'The user has been deleted.', 'success');
      } catch (error) {

      }
    }
  };

  return (
    <div className="container">
      <UserList users={users} onDeleteUser={handleDeleteUser}/>
    </div>
  );
};

export default Users;
