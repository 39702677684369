import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_ENDPOINTS, HEADER } from '../context/config';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  let navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const fetchUserDetail = async () => {
    try {
      const id = localStorage.getItem("Id")
      // Send a GET request to fetch user details
      const response = await axios.get(API_ENDPOINTS.GET_USER + id, HEADER); // Replace ':userId' with the actual user ID

      setUser(response.data);
    } catch (error) {
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      Swal.fire('Error', 'Passwords do not match', 'error');
      return;
    }
    if (oldPassword === newPassword) {
      Swal.fire('Error', 'Please set a new Password', 'error');
      return;
    }
    try {

      const id = localStorage.getItem("Id")
      // Send a POST request to update the user's password
      await axios.put(API_ENDPOINTS.UPDATE_USER + id, {oldPassword, newPassword}, HEADER);
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      Swal.fire({
        title: 'Please Re-Login',
        text: 'Password has been changed successfully!',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.isConfirmed) {

      localStorage.removeItem('token');
      localStorage.removeItem('name');
      localStorage.removeItem('role');
      localStorage.removeItem('Id');

      navigate('/login');
      window.location.reload();
    }
  });
    } catch (error) {
      Swal.fire('Error', 'Please retry using correct passwords', 'error');
    }
  };

  return (
    <>
    <div className="card">
  <div className="card-header">
    My Profile
  </div>
  <div className="card-body">
    <h5 className="card-title">Name: {user.userId.name}</h5>
    <p className="card-text">Email: {user.email}</p>
    <button className="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
      aria-expanded="false" aria-controls="collapseExample">
      Change Password
    </button>
  </div>
</div>

<div className='mt-3'>
    <div className="collapse" id="collapseExample">
      <div className="card card-body">
      <h3>Change Password</h3>
      <form onSubmit={handleChangePassword}>
        <div className="mb-2">
          <label className="form-label" htmlFor="oldPassword">Current Password</label>
          <input className="form-control"
            type="password"
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="newPassword">New Password</label>
          <input className="form-control"
            type="password"
            id="newPassword"
            value={newPassword}
            minLength={8}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-2">
          <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
          <input className="form-control"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            minLength={8}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button className='btn btn-primary mt-3' type="submit">Change Password</button>
      </form>

      </div>
    </div>
  </div>
</>
  )
}

export default Profile