import React from "react";
import { Link } from "react-router-dom";
import odoo from "../Assets/odoo.png";
import quickbooks from "../Assets/quickbooks.jpg";
import tally from "../Assets/Tally.png";
import xero from "../Assets/xero.png";
import whyvm from "../Assets/Why Choose VM.png";
import banner from "../Assets/Banner img.png";
import Testimonial from "./Testimonial";

const Home = () => {
  
  return (
    <>
      <header className="py-5 gradinet">
  <div className="container px-5">
    <div className="row gx-5 align-items-center justify-content-center">
      <div className="col-lg-7 col-xl-7 col-xxl-6">
        <div className="my-5 text-center text-xl-start">
          <h1 className="display-5 fw-bolder text-white mb-2">
            We'll do the <span className="text-dark">Accounting</span> <br /> You Grow your <span style={{color: "#1E4683"}}>Business</span>
          </h1>
          <p className="lead text-white mb-4">
            Entrepreneurs and Business Owners trust VM to manage accounts,
            reports and more. Get a dedicated accountant who knows your
            business
          </p>
          <div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
            <Link
              className="btn btn-dark btn-lg px-4 me-sm-3"
              to="/packages"
            >
              GET STARTED
            </Link>
            <Link
              className="btn btn-outline-light btn-lg px-4"
              to="/services"
            >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>
      <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center">
        <img
          className="img-fluid"
          src={banner}
          alt="Website Banner"
          style={{ maxWidth: "500px", height: "350px", marginTop: "2rem" }}
        />
      </div>
    </div>
  </div>
</header>


      {/* Advantages */}
      <div style={{backgroundColor: "#1055B2", color:"white"}}>
        <div className="card-header py-5">
          <h3 className="text-center mb-3">ADVANTAGES OF VIRTUAL ACCOUNTING</h3>

          <div
            className="container card-body"
            style={{ backgroundColor: "#204E94" }}
          >
            <div className="row mx-4 my-4">
              <div className="col-md-6">
                <ul>
                  <li>Cost Saving</li>
                  <li>Easy Access to Financial Expertise and Experience</li>
                  <li>Automation and Real-time Access to Financial Data</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Time Saving</li>
                  <li>Scalability and Flexibility</li>
                  <li>Allow You to Focus on Core Business Operation</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Softwares */}

      <div className="container py-5 shadow-lg">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center fw-bolder mb-4">
              MULTI-PLATFORM SUPPORT FOR ACCOUNTING SOFTWARE
            </h2>
          </div>
          <div className="col-12 col-md-3 my-1 text-center">
            <img src={odoo} alt="Odoo" />
          </div>
          <div className="col-12 col-md-3 my-1 text-center">
            <img src={xero} alt="Xero" />
          </div>
          <div className="col-12 col-md-3 my-1 text-center">
            <img src={quickbooks} alt="QuickBooks" />
          </div>
          <div className="col-12 col-md-3 my-1 d-flex align-items-center text-center">
            <img src={tally} alt="Tally" />
          </div>
        </div>
      </div>

      {/* <!-- WHY VIRTUAL MUHASIB--> */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img
                className="img-fluid rounded mb-5 mb-lg-0"
                src={whyvm}
                alt="Why Choose VM"
              />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-bolder " style={{color: "#1E4683"}}>WHY VIRTUAL MUHASIB</h2>
              <div className="lead">
                <ul>
                  <li>VM give you lower cost with higher benefits</li>
                  <li>
                    VM knows the importance of privacy and confidentiality you
                    can be sure that your financial information is secured
                  </li>
                  <li>Real Time information</li>
                  <li>
                    VM do more than process transaction for you we help you
                    understand the numbers driving your business
                  </li>
                  <li>Affordable Pricing</li>
                  <li>Support 24/7</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Trusted by businesses */}
      <div className="theme-color py-5">
      <div className="container">
        <h2 className="h3 block-title text-center mb-5 fw-bolder">
          TRUSTED BY BUSINESSES AROUND THE WORLD
        </h2>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          <div className="col">
              <div className="card-body text-center">
                <h1 className="fw-bold">89%</h1>
                <p className="card-title">
                  of customers recommend VM's service
                </p>
              </div>
          </div>
          <div className="col">
              <div className="card-body text-center">
                <h1 className="fw-bold">40 hours</h1>
                <p className="card-title">
                  freed up from admin work monthly
                </p>
              </div>
          </div>
          <div className="col">
              <div className="card-body text-center">
                <h1 className="fw-bold">1500+</h1>
                <p className="card-title">
                  VM's clients all over the world
                </p>
              </div>
          </div>
        </div>
      </div>
      </div>

<div>
      {/* Testimonial */}
      <Testimonial />
      </div>
    </>
  );
};

export default Home;
