const API_BASE_URL = 'https://server-xnit.onrender.com';
const API_ENDPOINTS = {
  LOGIN: `${API_BASE_URL}/api/auth/login`,
  USERS: `${API_BASE_URL}/api/auth/users`,
  GET_USER: `${API_BASE_URL}/api/auth/user/`,
  CREATE_USER: `${API_BASE_URL}/api/auth/createuser`,
  DELETE_USER: `${API_BASE_URL}/api/auth/user/`,
  UPDATE_USER: `${API_BASE_URL}/api/auth/password/`,
  // Project CRUD
  GET_PROJECT: `${API_BASE_URL}/api/project/project/`,
  GET_PROJECTS: `${API_BASE_URL}/api/project/projects`,
  CREATE_PROJECT: `${API_BASE_URL}/api/project/newproject/`,
  DELETE_PROJECT: `${API_BASE_URL}/api/project/deleteProject/`,
  UPDATE_PROJECT: `${API_BASE_URL}/api/project/updateProject/`,
  // Document CRUD
  ADD_DOC: `${API_BASE_URL}/api/document/newdocument/`,
  GET_DOC: `${API_BASE_URL}/api/document/documents/`,
  DEL_DOC: `${API_BASE_URL}/api/document/deletedocument/`,
  DOWNLOAD_DOC: `${API_BASE_URL}/api/document/download`,
  // Voucher CRUD
  ADD_VOUCHER: `${API_BASE_URL}/api/voucher/newvoucher/`,
  GET_VOUCHERS: `${API_BASE_URL}/api/voucher/vouchers`, //Admin route
  GET_VOUCHER: `${API_BASE_URL}/api/voucher/voucher/`,
  DELETE_VOUCHER: `${API_BASE_URL}/api/voucher/deletevoucher/`,
  UPDATE_VOUCHER: `${API_BASE_URL}/api/voucher/updatevoucher/`,
  // Contact CRD
  ADD_CONTACT: `${API_BASE_URL}/api/contact/contact`,
  GET_CONTACTS: `${API_BASE_URL}/api/contact/contacts`,
  DELETE_CONTACT: `${API_BASE_URL}/api/contact/contact/`, 

};

const HEADER = {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    };

export { API_ENDPOINTS,HEADER };