import React from "react";
import { Link } from "react-router-dom";

const Faqs = () => {
  return (
    <section className="py-4">
      <div className="container px-5">
        <div className="text-center mb-5">
          <h1 className="fw-bolder">Frequently Asked Questions</h1>
          <p className="lead fw-normal text-muted mb-0">How can we help you?</p>
        </div>
        <div className="row gx-5">
          <div className="col-xl-8">
            {/* <!-- FAQ Accordion 1--> */}
            <div className="accordion mb-5" id="accordionExample">
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    How much do bookkeeping services cost?
                  </button>
                </h3>
                <div
                  className="accordion-collapse collapse show"
                  id="collapseOne"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>We have flexible plans </strong>
                    depending on the number of transactions your
business has. A transaction is any deposit into or withdrawal from the bank.
This way you only pay for the real work done.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
Which accounting software do you use?
                  </button>
                </h3>
                <div
                  className="accordion-collapse collapse"
                  id="collapseTwo"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>We utilize a range </strong>
                    of accounting software solutions, including Odoo, QuickBooks,
Tally, and Xero.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h3 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
How accounting with VM works?
                  </button>
                </h3>
                <div
                  className="accordion-collapse collapse"
                  id="collapseThree"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <strong>Clients use their preferred </strong>
                    accounting software and simply upload
documents via their devices. Rest is handled by us
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card border-0 bg-light mt-xl-3">
              <div className="card-body p-4 py-lg-5">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <div className="h6 fw-bolder">Have more questions?</div>
                    <p className="text-muted mb-4">
                      <Link className="text-primary" to="/contact">Contact us</Link> <br/> <span className="fw-bold">OR</span> <br/>Email at
                      <br />
                      <a className="text-primary" href="mailto:info@virtualmuhasib.com">info@virtualmuhasib.com</a>
                    </p>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faqs;
